<template>
  <div class='indexbox'>
    <div class="searchbox">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/setting' }">Setting</el-breadcrumb-item>
        <el-breadcrumb-item>User</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-loading='loading' class="tablebox">
      <div class="btn_box">
        <span class="text">Permission</span>
        <el-button @click="confirm()">
          <img alt="" src="../../assets/img/top01.png"/>
          Save
        </el-button>
      </div>
      <div class="topbox">
        <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event)">Select All / Select None</el-checkbox>
      </div>
      <div class="bodybox">
        <el-tree ref="menu" :data="rulelist" :default-checked-keys='rules' :props="defaultProps" default-expand-all node-key="id" show-checkbox></el-tree>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      id: this.$route.query.id,
      rulelist: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      menuNodeAll: false,
      rules: [],
      loading: false,
      loadinga: false,
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  created() {
    this.getruleList()
  },
  methods: {
    getruleList() {
      this.loading = true
      this.$post({
        url: "/app/api_user/ruleList",
        data: {
          api_id: this.$store.state.user.api_id
        },
        success: (res) => {
          let dataa = {
            id: 100,
            title: "Converting Order",
            children: [],
          }
          res.data.forEach(item => {
            dataa.children.push(item)
          });
          this.rulelist = [dataa]
          this.getuser()
        },
        tip: () => {
          this.loading = false
        },
      })

    },
    getuser() {
      this.$post({
        url: "/app/api_user/info",
        data: {
          api_id: this.$store.state.user.api_id,
          id: this.id,
        },
        success: (res) => {
          if (res.data.rules) {
            this.rules = res.data.rules.split(',')
          }
          this.loading = false
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value) {
      this.$refs.menu.setCheckedNodes(value ? this.rulelist : []);
    },
    // 所有部门节点数据
    getDeptAllCheckedKeys() {
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    confirm() {
      // this.loadinga = true
      // this.loading = true
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      checkedKeys.map(item => {
        if (item > 99) {
          checkedKeys.splice(checkedKeys.indexOf(item), 1)
        }
      })
      this.$post({
        url: "/app/api_user/editRule",
        data: {
          api_id: this.$store.state.user.api_id,
          id: this.id,
          rules: checkedKeys.join(),
        },
        success: (res) => {
          this.loading = false
          this.loadinga = false
          this.$notify({
            title: 'Success',
            type: 'success'
          });
          this.$router.go(0)
        },
        tip: () => {
          setTimeout(() => {
            this.loading = false
            this.loadinga = false
          }, 1500);
        },
      })
    },
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;

  .searchbox {
    width: 100%;
    padding: 30px 36px;
    background: #fff;
    //border-bottom: 1px solid #babdcd;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tablebox {
    width: 100%;
    padding: 30px;

    .btn_box {
      border-radius: 6px 6px 0 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      padding: 25px 30px;
      background: #ffffff;
      justify-content: space-between;
      border-bottom: 1px solid #babdcd;

      .text {
        font-size: 20px;
        font-weight: 600;
        color: #717988;
      }

      /deep/ .el-button {
        font-weight: 500;
        color: #3b3b3b;
        background-color: #d6f3e3;
        border-radius: 10px;
        border: none;
        padding: 7px 13px;

        span {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }

    .topbox {
      width: 100%;
      padding: 25px 30px;
      font-weight: 550;
      background-color: #ffffff;
      border-bottom: 1px solid #babdcd;
    }

    .bodybox {
      width: 100%;
      padding: 25px 30px;
      background: #fff;
      border-radius: 0 0 6px 6px;

      /deep/ .el-tree {
        > .el-tree-node {
          > .el-tree-node__content {
            font-weight: 550;
          }
        }
      }
    }
  }
}
</style>
